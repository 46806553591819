import React, {useState, useEffect, useCallback, useRef} from 'react';
import PostTextComponent from './instagram/PostTextComponent';
import CarouselComponent from './instagram/CarouselComponent';
import instagramCommentsIcon from '../assets/icons/ig-comments.svg';
import instagramLikesIcon from '../assets/icons/ig-likes.svg';
import { isEmptyObject } from  '../util/utils';
import {POST_ENTITY} from '../util/const';


let vkWidgetInterval = null;

export default ({ vk }) => {

  const slider = useRef(null);
  const [currentPostsCount, setCurrentPostsCount] = useState(vk?.posts?.length ? vk?.posts?.length : null);
  const [mainCarouselIndex, setMainCarouselIndex] = useState(0);
  const [upIndex, setUpIndex] = useState(2)
  const [bottomIndex, setBottomIndex] = useState(1);

  useEffect(() => {
    if (vk.posts) {
      let nextPostsCount = vk.posts.length
      if (currentPostsCount !== nextPostsCount) {
        setMainCarouselIndex(0)
        setUpIndex(2)
        setBottomIndex(1)
      }
      setCurrentPostsCount(nextPostsCount)
      if (vkWidgetInterval) {
        clearInterval(vkWidgetInterval);
      }
      vkWidgetInterval = setInterval(() => {
        let postsLength = vk.posts.length
        setMainCarouselIndex(mainCarouselIndex => (mainCarouselIndex + 1 >= postsLength) ? 0 : mainCarouselIndex + 1);
        setUpIndex(upIndex => (upIndex + 1 >= postsLength) ? 0 : upIndex + 1);
        setBottomIndex(bottomIndex => (bottomIndex + 1 >= postsLength) ? 0 : bottomIndex + 1);
      }, vk.transition_duration * 1000);
    }
    return () => {
      if (vkWidgetInterval) {
        clearInterval(vkWidgetInterval);
      }
    }
  }, [vk]);

  if (isEmptyObject(vk) || !currentPostsCount) {
    return null
  }

  return (
    <div className="instagram-container">
      <div className="instagram-carousel-container">
        <div className="instagram-main-carousel">
          <div className="instagram-stats instagram-likes">
            <img className="instagram-icon" src={instagramLikesIcon}/>
            <div className="instagram-stats-count" id="likes">{vk.posts[mainCarouselIndex === vk.posts.length ? 0 : mainCarouselIndex]?.like_count}</div>
          </div>
          <div className="instagram-stats instagram-comments">
            <img className="instagram-icon" src={instagramCommentsIcon}/>
            <div className="instagram-stats-count" id="comments">{vk.posts[mainCarouselIndex === vk.posts.length ? 0 : mainCarouselIndex]?.comments_count}</div>
          </div>
          <div className="instagram-content" ref={slider}>
            <CarouselComponent posts={vk.posts}
                               currentIndex={mainCarouselIndex}
                               initialIndex={0}
                               isMain={true}
                               interval={vk.transition_duration || 1000}
                               onChangeIndex={index => setMainCarouselIndex(index)}/>
          </div>
        </div>
        <div className="instagram-up-carousel">
          <CarouselComponent posts={vk.posts}
                             isVertical={true}
                             currentIndex={upIndex}
                             direction={-1}
                             initialIndex={2}
                             interval={vk.transition_duration}/>
        </div>
        <div className="instagram-bottom-carousel">
          <CarouselComponent posts={vk.posts}
                             currentIndex={bottomIndex}
                             initialIndex={1}
                             interval={vk.transition_duration}/>
        </div>
      </div>

      <PostTextComponent posts={vk.posts}
                         isVertical={true}
                         index={mainCarouselIndex}
                         entity={POST_ENTITY.VK}
                         interval={vk.transition_duration}
                         photo={vk.community.community_image_url}
                         username={vk.community.community_name}/>
    </div>
  )
}