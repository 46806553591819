import React, {useState, useRef, useEffect} from 'react';
import ReactPlayer from 'react-player/lazy';
import root from 'react-shadow';
import {DateTime} from 'luxon';
import {isEmptyObject} from '../util/utils';


const animationsByType = {
  //fadeIn: 'fade-in',
  //fadeInDown: 'fade-in-down',
  //fadeOut: 'fade-out',
  //fadeOutDown: 'fade-out-down',
  slideInRight: 'slide-in-right',
  slideInLeft: 'slide-in-left',
}

export default ({videos, photo, setVideos}) => {

  let timer = null;
  let errorTimer = null;
  const [playIndex, setPlayIndex] = useState(0)
  const [background, setBackground] = useState(photo.photo_url)
  const [htmlContent, setHtmlContent] = useState(photo.html_code)
  const [showVideo, setShowVideo] = useState(!photo.show_immediately)
  const [loop, setLoop] = useState(videos?.playlist?.length <= 1)

  const [rerenderState, setRerenderState] = useState(true);

  // const buttonRight = useRef();
  // const buttonLeft = useRef();

  const restartTimer = (photoDatetime) => {
    let currentDatetime = DateTime.local().toUTC()
    if (photoDatetime - currentDatetime > (60 * 60 * 1000)) {
      timer = setTimeout(() => {
        return restartTimer(photoDatetime)
      }, 60 * 60 * 1000)
    } else {
      timer = setTimeout(() => {
        setShowVideo(false)
      }, photoDatetime - currentDatetime)
    }
  }

  const checkVideoConnection = () => {
    fetch(videos.playlist[playIndex].url, {
      method: 'GET'
    })
      .then(res => {
        if(res.status >= 200 && res.status <= 400) {
          setPlayIndex(0)
          setRerenderState(!rerenderState);
          setLoop(videos?.playlist?.length <= 1)
          return () => {
            clearTimeout(errorTimer)
          }
        }
        errorTimer = setTimeout(() => {
          return checkVideoConnection()
        }, 5000)
      })
      .catch(error => {
        errorTimer = setTimeout(() => {
          return checkVideoConnection()
        }, 5000)
      })
  }

  useEffect(() => {
    setBackground(photo.photo_url)
    setHtmlContent(photo.html_code)
    setShowVideo(!photo.show_immediately)
    if (photo.show_by_time && photo.datetime) {
      let photoDatetime = DateTime.fromISO(photo.datetime)
      let currentDatetime = DateTime.local().toUTC()
      if (!photoDatetime.invalid && (photoDatetime > currentDatetime)) {
        clearTimeout(timer)
        restartTimer(photoDatetime)
      }
    }
    return () => {
      clearTimeout(timer)
    }
  }, [photo])

  useEffect(() => {
    setPlayIndex(0);
    setRerenderState(!rerenderState);
    setLoop(videos?.playlist?.length <= 1)
  }, [videos])

  useEffect(() => {
    let showTimer = null
    if (!showVideo && photo.show_by_time) {
      showTimer = setTimeout(() => {
        setShowVideo(true);
        setPlayIndex(0);
      }, photo.show_duration * 1000)
    }
    return () => {
      clearTimeout(showTimer)
    }
  }, [showVideo]);

  //console.log('videos: ', videos, '/ playIndex: ', playIndex);
  //console.log('rerenderState', rerenderState);
  //console.log('==============')

  if (isEmptyObject(videos) || !videos?.playlist?.length) {
    return null
  }

  return (
    <div className="video-container">
      <div style={{display: 'none'}}>{rerenderState}</div>
      <div className="video-block">
        {showVideo
          ? (
            <ReactPlayer style={{position: "absolute", top: 0, left: 0}}
                         // ref={player}
                         url={videos?.playlist[playIndex]?.url}
                         controls={false}
                         playing={true}
                         loop={loop}
                         config={{
                           file: {
                             attributes: {
                               preload: 'metadata'
                             }
                           }
                         }}
                         volume={0}
                         onError={() => {
                           if (checkVideoConnection) {
                             checkVideoConnection();
                           }
                         }}
                         muted={true}
                         width="100%"
                         height="84.87654vh"
                         progressInterval={1000}
                         onEnded={() => {
                           setPlayIndex((playIndex !== videos.playlist.length - 1) ? playIndex + 1 : 0)
                         }}
                         />
          ) : (
              <root.div style={{
                backgroundImage: (background) ? `url(${background})` : 'none',
              }}
                className={htmlContent ? 'video-block-alternate' : 'video-block-alternate image-content'}>
                {
                  htmlContent && (
                    <div style={{
                      background: '#ffffff',
                      width: '100%',
                      height: '100%'
                    }} dangerouslySetInnerHTML={{__html: htmlContent}}/>
                  )
                }
              </root.div>
          )
        }
      </div>
    </div>
  );
}