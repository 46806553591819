import clear from '../lotties/clear';
import clear_n from '../lotties/clear_n';
import clouds from '../lotties/clouds';
import clouds_light from '../lotties/clouds_light';
import clouds_light_n from '../lotties/clouds_light_n';
import clouds_n from '../lotties/clouds_n';
import clouds_rain from '../lotties/clouds_rain';
import clouds_rain_n from '../lotties/clouds_rain_n';
import clouds_snow from '../lotties/clouds_snow';
import clouds_snow_n from '../lotties/clouds_snow_n';
import haze from '../lotties/haze';
import haze_n from '../lotties/haze_n';
import rain from '../lotties/rain';
import rain_n from '../lotties/rain_n';
import rain_snow from '../lotties/rain_snow';
import thunder from '../lotties/thunder';
import thunder_n from '../lotties/thunder_n';
import thunder_rain from '../lotties/thunder_rain';


export default {
  'clear': clear,
  'clear_n': clear_n,
  'clouds': clouds,
  'clouds_light': clouds_light,
  'clouds_light_n': clouds_light_n,
  'clouds_n': clouds_n,
  'clouds_rain': clouds_rain,
  'clouds_rain_n': clouds_rain_n,
  'clouds_snow': clouds_snow,
  'clouds_snow_n': clouds_snow_n,
  'haze': haze,
  'haze_n': haze_n,
  'rain': rain,
  'rain_n': rain_n,
  'rain_snow': rain_snow,
  'thunder': thunder,
  'thunder_n': thunder_n,
  'thunder_rain': thunder_rain
}
