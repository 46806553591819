// Sections

export const BACKGROUND = 'background'

export const GREETING = 'greeting'

export const PHOTO = 'photo'

export const WEATHER = 'weather'

export const VIDEOS = 'videos'

export const INSTAGRAM = 'instagram'

export const VK = 'vk'

export const POST_ENTITY = {
  VK: 'vk',
  INSTAGRAM: 'instagram'
}