import React, {useState, useEffect} from 'react'
import defaultLogo from '../logo.svg'
import {DateTime} from 'luxon';
import {getMonthRussianName} from "../util/utils";


export default ({ greeting }) => {

  const [currentDateTime, setCurrentDateTime] = useState(DateTime.local().setLocale('ru'))

  useEffect(() => {
    const timeInterval = setInterval(() => {
      setCurrentDateTime(DateTime.local().setLocale('ru'))
    }, 1000);
    return () => clearInterval(timeInterval);
  }, []);

  return (
    <div className="greeting-container">
      <div className="greeting-logo-wrapper" >
        <img src={(greeting.logo_url === undefined || greeting.logo_url.trim() === '') ? defaultLogo : greeting.logo_url}
           className="greeting-logo" alt={"logo"}/>
      </div>
      <div className="greeting-block">
        <div className="greeting-block-title" dangerouslySetInnerHTML={{__html: greeting.text || 'Добро пожаловать!'}} />
        <div className="greeting-block-date">
          {currentDateTime.day} {getMonthRussianName(currentDateTime.month)}, {currentDateTime.weekdayLong}
        </div>
        <div className="greeting-block-time">
          {currentDateTime.hour < 10 && '0'}{currentDateTime.hour}:{currentDateTime.minute < 10 && '0'}{currentDateTime.minute}
        </div>
      </div>
    </div>
  )
}