import React, { useState, useRef, useEffect, useCallback } from 'react';
import './carousel.css';

const SimpleCarousel = ({slides, isVertical, currentIndex = 0, direction = 1, children}) => {
  const items = useRef(null);
  const slider = useRef(null);


  useEffect(() => {
    if (items && items.current) {

      if (isVertical) {
        items.current.classList.add('shifting-vertical');
        if (direction === 1) {
          items.current.style.top = `-${(currentIndex + 1) * 100}%`;
        } else if (direction === -1) {
          items.current.style.top = `-${(slides.length) * 100 - (currentIndex) * 100}%`;
        }
      } else {
        items.current.classList.add('shifting');
        if (direction === 1) {
          items.current.style.left = `-${(currentIndex + 1) * 100}%`;
        } else if (direction === -1) {
          items.current.style.left = `-${(slides.length) * 100 - (currentIndex) * 100}%`;
        }
      }
    }
  }, [currentIndex]);

  const checkIndex  = () => {
    if (items && items.current) {

      if (isVertical) {
        items.current.classList.remove('shifting-vertical');

        if (currentIndex + 1 === slides.length) {
          items.current.style.top = direction === -1 ? `-${(slides.length + 1) * 100}%` : '0%';
        }
      } else {
        items.current.classList.remove('shifting');

        if (currentIndex + 1 === slides.length) {
          items.current.style.left = direction === -1 ? `-${(slides.length + 1) * 100}%` : '0%';
        }
      }
    }
  }

  if (isVertical) {
    return (
      <div className="slider" ref={slider}>
        <div className="wrapper">
          <div className={`slides vertical ${direction === -1 ? 'ttb' : ''}`} onTransitionEnd={checkIndex} ref={items} style={{
            height: `${(slides.length + 2) * 100}%`,
            top: '-100%'//`-${initialIndex + 1}00%`
          }}>
            {children}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="slider" ref={slider}>
      <div className="wrapper">
        <div className={`slides ${direction === -1 ? 'rtl' : ''}`} onTransitionEnd={checkIndex} ref={items} style={{
          width: `${(slides.length + 2) * 100}%`,
          left: '-100%'//`-${initialIndex + 1}00%`
        }}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default SimpleCarousel;