import openSocket from 'socket.io-client';

const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

function subscribeToData(cb) {
  socket.on('updateData', (data) => {
    cb(null, data)
  });
  socket.emit('subscribeToData', 1000);
}

export {subscribeToData};