import React from 'react'

export function getMonthRussianName(id) {
  switch (id) {
    case 1:
      return 'Января'
    case 2:
      return 'Февраля'
    case 3:
      return 'Марта'
    case 4:
      return 'Апреля'
    case 5:
      return 'Мая'
    case 6:
      return 'Июня'
    case 7:
      return 'Июля'
    case 8:
      return 'Августа'
    case 9:
      return 'Сентября'
    case 10:
      return 'Октября'
    case 11:
      return 'Ноября'
    case 12:
      return 'Декабря'
  }
}

export function getWeatherColor(temperature) {
  if (temperature > 0) {
    return '#FF6B00'
  }
  return '#0e3e8a'
}

export function saveToLocalStorage(item, value) {
  window.localStorage.setItem(item, JSON.stringify(value))
}

export function readFromLocalStorage(item) {
  return JSON.parse(window.localStorage.getItem(item))
}

export function readStringFromLocalStorage(item) {
  return window.localStorage.getItem(item)
}

export function getUTCDateInRussianFormat(timezone_offset) {
  let date = (new Date().getTime()) + (timezone_offset * 1000)
  return {
    date: new Date(date).toLocaleString('ru', {
      timeZone: 'UTC',
      day: 'numeric',
      month: 'long',
    }),
    time: new Date(date).toLocaleString('ru', {
      timeZone: 'UTC',
      hour: 'numeric',
      minute: 'numeric'
    })
  };
}

export function isEmptyObject(obj) {
  for (let i in obj) {
    if (obj.hasOwnProperty(i)) {
      return false;
    }
  }
  return true;
}