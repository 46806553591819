import React, {useState, useEffect} from 'react'
import {getMonthRussianName, getUTCDateInRussianFormat, getWeatherColor, isEmptyObject} from "../util/utils";
import Lottie from 'react-lottie'
import lottieIcons from "../lotties"


export default ({ weathers }) => {

  const defaultCardName = 'weather-card'
  const activeCardName = 'weather-card-active'
  const [weatherIndex, setWeatherIndex] = useState(0)
  let weatherInterval = null

  useEffect(() => {
    if (!isEmptyObject(weathers)) {
      if (weatherInterval) {
        clearInterval(weatherInterval)
      }
      weatherInterval = setInterval(() => {
        setWeatherIndex(weatherIndex => (weatherIndex < weathers.cities.length - 1) ? weatherIndex + 1 : 0)
      }, weathers.transition_duration * 1000);
    }
    return () => clearInterval(weatherInterval);
  }, [weathers]);

  if (isEmptyObject(weathers) || !weathers.cities.length) {
    return null
  }

  return (
    <div className="weather-container">
      <div className="weather-block">
        {
          weathers.cities.slice(0,4).map((weather, index) => {
            let cityDateTime = getUTCDateInRussianFormat(weather.timezone_offset)
            return (
              <div key={index} className={`${defaultCardName} ${(index === weatherIndex) ? activeCardName : ''}`}>
                <div className="weather-card-header">
                  <div className="weather-card-header-container">
                    <div className="weather-card-header-city">{weather.city_name}</div>
                    <div className="weather-card-header-date">{cityDateTime.date}</div>
                  </div>
                  <div className="weather-card-header-icon">
                    <div className="weather-card-header-icon-wrapper">
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: lottieIcons[weather.icon],
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="weather-card-footer">
                  <div
                    className="weather-card-footer-time">{cityDateTime.time}</div>
                  <div className="weather-card-footer-celsius"
                       style={{color: getWeatherColor(weather.temperature)}}>{weather.temperature}&deg;</div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}