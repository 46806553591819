import React, {useState, useEffect, useRef} from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import SimpleCarousel from '../Carousel/Carousel';
import animateScrollTo from 'animated-scroll-to';
import {POST_ENTITY} from '../../util/const';


export default ({posts, index, photo, username, interval, isVertical, entity = POST_ENTITY.INSTAGRAM}) => {

  return (
    <div className="instagram-text-container">
      <div className="instagram-text-header">
        <div className="instagram-text-photo" style={{background: `url(${photo}) no-repeat center #ffffff`}}/>
        <div>
          <div className="instagram-text-header-name">{username}</div>
          <img className="instagram-text-header-logo" src={`${process.env.PUBLIC_URL}/media/images/${entity}.svg`} alt="logo"/>
        </div>
      </div>
      <div className="instagram-text-description">
        <SimpleCarousel slides={posts} isVertical={isVertical} currentIndex={index} direction={1}>
          <TextSlide ind={posts.length-1} selectIndex={index} post={posts[posts.length-1]} interval={interval}/>
            {
              posts.map((post, i) => (
                <TextSlide key={i} ind={i} selectIndex={index} post={post} interval={interval}/>
              ))
            }
          <TextSlide ind={0} selectIndex={index} post={posts[0]} interval={interval}/>
        </SimpleCarousel>
      </div>
    </div>
  )
}

const TextSlide = ({ind, selectIndex, post, interval}) => {
  const [rectWrapper, setRectWrapper] = useState(null);
  const [animation, setAnimation] = useState(null);

  const slide = useRef(null);
  const slideWrapper = useRef(null);
  const timeOffset = 100

  useEffect(() => {
    if ((ind === selectIndex) && slide && slide.current) {
      let wrap = rectWrapper;
      if (!rectWrapper) {
        wrap = slideWrapper.current.getBoundingClientRect();
      }
      const rect = slide.current.getBoundingClientRect();

      if (Math.round(rect.height - (wrap.height / 1.5)) > 0) {
        slideWrapper.current.scrollTo(0, 0)
        setTimeout(() => {
          animateScrollTo(document.getElementById(`instagram-post-slide-end-${ind}`), {
            elementToScroll: document.getElementById(`instagram-post-slide-${ind}`),
            maxDuration: interval * 1000 - timeOffset,
            minDuration: interval * 1000 - timeOffset,
            speed: interval * 1000 - (timeOffset * 2),
            easing: (t) => t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
          })
        }, 1500 + timeOffset);
      }

      // if (animation) {
      //   animation.play();
      // } else {
      //   let scrollHeight = rect.height - (wrap.height / 1.5)
      //   const newAnimation = slide.current.animate(
      //     [
      //       // keyframes
      //       { transform: 'translate3D(0, 0, 0)' },
      //       { transform: `translate3D(0, -${ (scrollHeight > 0) ? scrollHeight : 0 }px, 0)` }
      //     ],
      //     {
      //       // timing options
      //       delay: 1500,
      //       duration: (interval + 1.5) * 1000
      //     }
      //   );
      //   setAnimation(newAnimation);
      // }
    }
  }, [slide, ind, selectIndex]);

  if (!post) {
    return null
  }

  return (
    <div ref={slideWrapper} id={`instagram-post-slide-${ind}`} className="instagram-post-slide">
      <div ref={slide}>
        {post.body}
      </div>
      <div className="instagram-post-slide-end" id={`instagram-post-slide-end-${ind}`}/>
    </div>
  )
}