import React from 'react';
import Carousel from 'nuka-carousel';
import SimpleCarousel from '../Carousel/Carousel';
import ReactPlayer from 'react-player/lazy';


export default ({posts, currentIndex, interval, isVertical = false, direction = 1, isMain, onChangeIndex}) => {

  if (!(posts && posts.length)) {
    return null;
  }

  return (
    <SimpleCarousel slides={posts} isVertical={isVertical} currentIndex={currentIndex} direction={direction}>
      <Post post={posts[posts.length - 1]}/>
      {posts.map((post, i) => {
        return (
          <Post key={i} post={post}/>
        )
      })}
      <Post post={posts[0]}/>
    </SimpleCarousel>
  )
}

const Post = ({post}) => {
  return (
    <div className="instagram-post-slide">
      {
        post.image_url.includes('https://video')
          ? <ReactPlayer url={post.image_url}
                         controls={false}
                         playing={false}
                         volume={0}
                         muted={true}
                         width="100%"
                         height="auto"
          />
          :  <div className="instagram-image" style={
            {
              backgroundImage: `url(${post.image_url})`
            }
          }/>
      }
    </div>
  )
}