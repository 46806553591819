import React, {useState, useEffect} from 'react';
//import InstagramComponent from "./components/InstagramComponent";
import VideoComponent from "./components/VideoComponent";
import GreetingComponent from "./components/GreetingComponent";
import WeatherComponent from "./components/WeatherComponent";
import BackgroundComponent from "./components/BackgroundComponent";
import {subscribeToData} from "./socket/socket";
import {readFromLocalStorage, readStringFromLocalStorage, saveToLocalStorage} from "./util/utils";
import {BACKGROUND, GREETING, INSTAGRAM, VK, PHOTO, VIDEOS, WEATHER} from "./util/const";
import queryString from 'query-string';
import VkComponent from './components/VkComponent';



export default (props) => {
  const qs = queryString.parse(window.location.search)
  let isPreview = (qs['preview']) ? qs['preview'] : false

  let [background, setBackground] = useState(readFromLocalStorage(BACKGROUND) || {})
  let [photo, setPhoto] = useState(readFromLocalStorage(PHOTO) || {})
  let [greeting, setGreeting] = useState(readFromLocalStorage(GREETING) || {})
  let [vk, setVk] = useState(readFromLocalStorage(VK) || {})
  // let [instagram, setInstagram] = useState(readFromLocalStorage(INSTAGRAM) || {})
  let [weather, setWeather] = useState(readFromLocalStorage(WEATHER) || {})
  let [videos, setVideos] = useState(readFromLocalStorage(VIDEOS) || {})

  useEffect(() => {
    subscribeToData((err, data) => {
      if (isPreview && data.preview) {
        setBackground(data.background);
        setGreeting(data.greeting);
        setWeather(data.weather);
        setPhoto(data.photo);
        setVideos(data.video);
        setVk(data?.vk || {})
        //setInstagram(data.instagram);
      } else {
        if (!isPreview && !data.preview) {
          // Background
          if (JSON.stringify(data.background) !== readStringFromLocalStorage(BACKGROUND)) {
            saveToLocalStorage(BACKGROUND, data.background)
            setBackground(data.background)
          }
          // Greeting
          if (JSON.stringify(data.greeting) !== readStringFromLocalStorage(GREETING)) {
            saveToLocalStorage(GREETING, data.greeting)
            setGreeting(data.greeting)
          }
          // Photo
          if (JSON.stringify(data.photo) !== readStringFromLocalStorage(PHOTO)) {
            saveToLocalStorage(PHOTO, data.photo)
            setPhoto(data.photo)
          }
          // Weather
          if (JSON.stringify(data.weather) !== readStringFromLocalStorage(WEATHER)) {
            saveToLocalStorage(WEATHER, data.weather)
            setWeather(data.weather)
          }
          // Video
          if (JSON.stringify(data.video) !== readStringFromLocalStorage(VIDEOS)) {
            saveToLocalStorage(VIDEOS, data.video)
            setVideos(data.video)
          }
          // VK
          if (JSON.stringify(data?.vk || {}) !== readStringFromLocalStorage(VK)) {
            saveToLocalStorage(VK, data?.vk || {})
            setVk(data?.vk || {})
          }
          // Instagram
          // if (JSON.stringify(data.instagram) !== readStringFromLocalStorage(INSTAGRAM)) {
          //   saveToLocalStorage(INSTAGRAM, data.instagram)
          //   setInstagram(data.instagram)
          // }
        }
      }
    })
  }, [])

  return (
    <div className="layout">
      <BackgroundComponent background={background}/>
      <GreetingComponent greeting={greeting}/>
      {/*<InstagramComponent instagram={instagram} />*/}
      <VkComponent vk={vk} />
      <VideoComponent videos={videos} setVideos={setVideos} photo={photo} />
      <WeatherComponent weathers={weather} />
    </div>
  )
}